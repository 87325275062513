<template>
  <section>
    <v-row class="fill-height">
      <v-col>
        <v-btn
          :to="`/site/${selectedSite.siteId}/strategies/create`"
          color="primary"
          :disabled="!selectedSite.siteId"
        >
          <v-icon left>fa-plus</v-icon>New Strategy
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          return-object
          @change="handleGetStrategies(item)"
          solo
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row v-if="noStrategies">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800"
        >No strategies configured</v-card
      >
    </v-row>

    <section>
      <v-row justify="start" align="start">
        <v-col
          v-for="strategy in strategies"
          :key="strategy.name"
          :sm="12"
          :md="6"
          :lg="4"
          :xl="3"
        >
          <v-card>
            <v-card-title class="title blue-grey lighten-4">
              {{ strategy.name }}
            </v-card-title>

            <v-divider />

            <v-card-text>
              <p>{{ strategy.description }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-switch
                v-model="strategy.isActive"
                @change="handleActivate(strategy)"
              ></v-switch>
              <v-spacer></v-spacer>
              <v-btn
                icon
                :to="`/site/${selectedSite.siteId}/strategies/${strategy.id}`"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="handleDeleteStrategy(strategy)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <confirm-dialog
        ref="confirmDelete"
        title="Delete this strategy?"
      ></confirm-dialog>
    </section>
  </section>
</template>
<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "./_api";
import ConfirmDialog from "../../../src/components/Dialogs/ConfirmDialog";
export default {
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  async created() {
    this.sites = await api.getSites();

    if (this.defaultSite !== null) {
      this.selectedSite = this.defaultSite;
      this.strategies = await api.getStrategies(this.selectedSite.siteId);
    }
  },
  data() {
    return {
      sites: [],
      selectedSite: {},
      strategies: [],
    };
  },
  methods: {
    ...mapActions({
      setDefaultSite: "session/setDefaultSite",
    }),
    async handleDeleteStrategy(strategy) {
      const msg =
        "Are you sure you want to delete this strategy?  The configured strategy actions will no longer run.";
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      //process here
      console.log(strategy);
      await api.deleteStrategy(strategy);
      this.strategies = await api.getStrategies(this.selectedSite.siteId);
    },

    async handleGetStrategies() {
      this.setDefaultSite(this.selectedSite);
      this.strategies = await api.getStrategies(this.selectedSite.siteId);
    },

    async handleActivate(strategy) {
      console.log(strategy);
      await api.activateStrategy(strategy);
    },
  },

  computed: {
    ...mapGetters({
      defaultSite: "session/defaultSite",
    }),
    noStrategies: function () {
      return this.strategies.length === 0;
    },
  },
};
</script>